import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

function Home() {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const naverClientID = process.env.REACT_APP_NAVER_CLIENT_ID;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout } = useAuth();
  const [showLogo, setShowLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const googleLoginRef = useRef();

  const loginWithKakao = () => {
    window.Kakao.Auth.authorize({
      redirectUri: appUrl,
      scope: "profile_nickname, profile_image, account_email",
      //   state: "userme",
      //   serviceTerms: "account_email",
    });
  };

  const requestUserInfo = async (tokenInfo) => {
    const res = await fetch("https://kapi.kakao.com/v2/user/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.Kakao.Auth.getAccessToken()}`,
      },
    });

    if (res.status === 401) {
      return;
    }

    const resJson = await res.json();

    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    const res2 = await $post("/api/kakao-login", {
      email: resJson.kakao_account.email,
      nickname: resJson.kakao_account.profile.nickname,
      profile_image_url: resJson.kakao_account.profile.profile_image_url,
      access_token: tokenInfo.access_token,
      access_token_expires_in: tokenInfo.expires_in,
      refresh_token: tokenInfo.refresh_token,
      refresh_token_expires_in: tokenInfo.refresh_token_expires_in,
      lang: userLanguage,
    });

    const userInfo = res2.data[0];
    setLoading(false);

    if (res2.status === 200) {
      login({
        ...userInfo,
      });

      // navigate("/work");

      // if (userInfo.role_level === 1) {
      //   if (userInfo.active_yn === "N") navigate("/waiting-list");
      // }
    }
  };

  const getToken = async (code) => {
    const res = await fetch("https://kauth.kakao.com/oauth/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      body: `grant_type=authorization_code&client_id=${process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY}&redirect_uri=${appUrl}&code=${code}`,
    });

    const resJson = await res.json();

    if (resJson.access_token) {
      window.Kakao.Auth.setAccessToken(resJson.access_token);
      requestUserInfo(resJson);
    }
  };

  const requestKaKaoInfo = async () => {
    const params = new URL(document.location.toString()).searchParams;
    const code = params.get("code");
    if (code) {
      setLoading(true);
      getToken(code);
    }
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const unlinkKakao = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      fetch("https://kapi.kakao.com/v1/user/unlink", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${res.accessToken}`,
        },
      })
        .then((res) => {
          window.Kakao.Auth.setAccessToken(null);
          logout();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("Not logged in.");
      return;
    }
  };

  const initKaKao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logoutWithKakao(res.accessToken);
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLanguage,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    if (res.status === 200) {
      login({
        ...userInfo,
      });
      // setTimeout(() => {
      //   navigate("/work");
      // }, 1000);
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  const loginWithGoogle = async () => {
    // console.log(`${serverUrl}/api/google-login`);
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  const setI18nLanguage = () => {
    let userLanguage = "EN";

    if (userLocalLang.startsWith("en")) {
      // 영어 사용자에 대한 처리
      userLanguage = "EN";
    } else if (userLocalLang.startsWith("ko")) {
      // 한국어 사용자에 대한 처리
      userLanguage = "KO";
    } else if (userLocalLang.startsWith("ja")) {
      // 일본어 사용자에 대한 처리
      userLanguage = "JP";
    } else if (userLocalLang.startsWith("de")) {
      // 독일어 사용자에 대한 처리
      userLanguage = "DE";
    } else if (userLocalLang.startsWith("fr")) {
      // 프랑스어 사용자에 대한 처리
      userLanguage = "FR";
    } else if (userLocalLang.startsWith("es")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "ES";
    } else if (userLocalLang.startsWith("nl")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "NL";
    } else if (userLocalLang.startsWith("sv")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "SV";
    } else if (userLocalLang.startsWith("ru")) {
      // 스페인어 사용자에 대한 처리
      userLanguage = "RU";
    }

    if (userData && userData.lang) userLanguage = userData.lang;

    i18n.changeLanguage(userLanguage);
  };

  useEffect(() => {
    initKaKao();
    requestKaKaoInfo();
    setI18nLanguage();
    const lightbox = GLightbox({
      selector: ".glightbox",
    });

    const lightbox2 = GLightbox({
      selector: ".glightbox2",
    });

    return () => {
      lightbox.destroy();
      lightbox2.destroy();
    };
  }, []);

  return (
    <main>
      <div className="carousel slide mb-6" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="./assets/images/bg_sdot1.jpg"
              className="d-block"
              style={{ filter: "brightness(50%)", backgroundSize: "cover" }}
              alt=""
            />
            <div className="container">
              <div className="carousel-caption text-center">
                <div className="row featurette">
                  <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center">
                    <h2 className="fw-normal">{t("SNS AI 어시스턴트")}</h2>
                    <p className="lead">
                      {t(
                        "Sdot과 함께라면 초보자도 쉽게 SNS 채널 운영으로 브랜드 인지도를 높일 수 있습니다."
                      )}
                    </p>
                    {/* Subscribe 버튼 */}
                    {isLoggedIn && (
                      <a
                        className="btn btn-danger btn-lg mt-3 mb-3"
                        style={{ width: "300px" }}
                        onClick={() => navigate("/work")}
                      >
                        {t("프로젝트 시작하기")}
                      </a>
                    )}

                    {!isLoggedIn && (
                      <div className="text-center pb-5">
                        {appUrl.includes("localhost") && (
                          <a
                            onClick={() => loginWithKakao()}
                            className="mb-1"
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              src="./assets/images/kakao-login-button.png"
                              width="300px"
                              height="auto"
                              alt="카카오 로그인 버튼"
                            />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 d-none d-lg-block">
                    <div style={{ position: "relative" }}>
                      <img
                        src="./assets/images/sdot-youtube.jpg"
                        alt="video"
                        className="rounded-3 shadow-lg"
                        width="100%"
                      />
                      <a
                        href={
                          userLocalLang.startsWith("ko")
                            ? "https://youtu.be/yHyy2zV0RkA?si=fFvi1PPRM7jZ2hVF"
                            : "https://youtu.be/LTVBMbDbl18?si=hZq_VhQBm0zsswxf"
                        }
                        className="play-btn glightbox2 position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
                        style={{
                          backgroundColor: "white",
                          cursor: "pointer",
                          top: "50%",
                          left: "50%",
                          position: "absolute",
                          boxShadow: "0 0 0 10px rgba(242, 41, 91, 0.3)",
                        }}
                      >
                        <i
                          className="fa fa-play-circle fa-3x"
                          style={{ color: "var(--tg-primary-main)" }}
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="carousel-caption text-center">
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column align-content-center justify-content-center">
                    <h1>{t("SNS AI 어시스턴트")}</h1>
                    <div className="p-2">
                      <h3>
                        {t(
                          "Sdot과 함께라면 초보자도 쉽게 SNS 채널 운영으로 브랜드 인지도를 높일 수 있습니다."
                        )}
                      </h3>
                    </div>
                  </div>
                  <div
                    style={{ position: "relative" }}
                    className="d-none d-lg-block"
                  >
                    <img
                      src="./assets/images/sdot-video.png"
                      alt="video"
                      className="rounded-3 shadow-lg"
                      height="300px"
                    />
                    <a
                      href="https://youtu.be/LTVBMbDbl18?si=hZq_VhQBm0zsswxf"
                      className="play-btn glightbox2 position-absolute top-50 start-50 translate-middle icon-shape icon-xl rounded-circle text-primary"
                      style={{
                        backgroundColor: "white",
                        cursor: "pointer",
                        top: "50%",
                        left: "50%",
                        position: "absolute",
                        boxShadow: "0 0 0 10px rgba(242, 41, 91, 0.3)",
                      }}
                    >
                      <i
                        className="fa fa-play-circle fa-3x"
                        style={{ color: "var(--tg-primary-main)" }}
                      ></i>
                    </a>
                  </div>
                </div>

                
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container marketing">
        <div className="row">
          <div className="col-lg-4">
            <div className="text-center mb-2">
              <i className="fa-brands fa-square-instagram fa-4x"></i>
            </div>

            <h3 className="fw-normal mb-2">{t("인스타그램 피드")}</h3>
            <div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-regular fa-circle-check"></i>{" "}
                  {t("인스타그램에 최적화된 피드 초안 생성")}
                </li>
                <li>
                  <i className="fa-regular fa-circle-check"></i>{" "}
                  {t("카드뉴스 제작 및 피드에 맞는 이미지 생성")}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-center mb-2">
              <i className="fa-brands fa-linkedin fa-4x"></i>
            </div>

            <h3 className="fw-normal mb-2">{t("링크드인 포스팅")}</h3>
            <div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-regular fa-circle-check"></i>{" "}
                  {t("사용자 커리어를 기반으로 한 업계 소식 생성")}
                </li>
                <li>
                  <i className="fa-regular fa-circle-check"></i>{" "}
                  {t("전문가 인사이트를 포함한 콘텐츠 초안 생성")}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="text-center mb-2">
              <i className="fa-brands fa-tiktok fa-4x"></i>
            </div>

            <h3 className="fw-normal mb-2">{t("숏폼 시나리오")}</h3>
            <div>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-regular fa-circle-check"></i>{" "}
                  {t("정보성 콘텐츠 숏폼 시나리오 제안")}
                </li>
                <li>
                  <i className="fa-regular fa-circle-check"></i>{" "}
                  {t("숏폼 영상 촬영 시나리오 제안")}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-5">
            <h3 className="featurette-heading fw-normal lh-1">
              {t("오늘의 주제 & 피드 내용 추천")}
            </h3>
            <p className="lead">
              {t(
                "꾸준하게 콘텐츠를 올려야하는 SNS 채널 운영자에게 가장 큰 고민은 “오늘은 무슨 주제의 글을 올리지?”입니다. Sdot을 활용하시면 주제에 대한 고민 끝~! Sdot에게 매일 콘텐츠 주제를 추천받아보세요!"
              )}
            </p>
          </div>
          <div className="col-md-7">
            {userData && userData.lang !== "KO" ? (
              <img
                src="./assets/images/gif/today_subject2_en.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("오늘의 주제 & 피드 내용 추천")}
                data-description={t(
                  "꾸준하게 콘텐츠를 올려야하는 SNS 채널 운영자에게 가장 큰 고민은 “오늘은 무슨 주제의 글을 올리지?”입니다. Sdot을 활용하시면 주제에 대한 고민 끝~! Sdot에게 매일 콘텐츠 주제를 추천받아보세요!"
                )}
                style={{ width: "100%" }}
              />
            ) : userLocalLang.startsWith("ko") ? (
              <img
                src="./assets/images/gif/today_subject2.gif"
                className="image-gif glightbox"
                alt=""
                data-title={t("오늘의 주제 & 피드 내용 추천")}
                data-description={t(
                  "꾸준하게 콘텐츠를 올려야하는 SNS 채널 운영자에게 가장 큰 고민은 “오늘은 무슨 주제의 글을 올리지?”입니다. Sdot을 활용하시면 주제에 대한 고민 끝~! Sdot에게 매일 콘텐츠 주제를 추천받아보세요!"
                )}
                style={{ width: "100%" }}
              />
            ) : (
              <img
                src="./assets/images/gif/today_subject2_en.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("오늘의 주제 & 피드 내용 추천")}
                data-description={t(
                  "꾸준하게 콘텐츠를 올려야하는 SNS 채널 운영자에게 가장 큰 고민은 “오늘은 무슨 주제의 글을 올리지?”입니다. Sdot을 활용하시면 주제에 대한 고민 끝~! Sdot에게 매일 콘텐츠 주제를 추천받아보세요!"
                )}
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette">
          <div className="col-md-5 order-md-2">
            <h3 className="featurette-heading fw-normal lh-1">
              {t("브랜드 채널 전문가로 변신!")}
            </h3>
            <p className="lead">
              {t(
                "운영하고자 하는 브랜드 채널에 대한 정보만 똑똑하게 입력하면 Sdot이 브랜드 채널 전문가로 변신시켜드립니다. 필요한 채널 별로 프로젝트를 등록하고 SNS 채널 운영을 쉽게 시작해보세요!"
              )}
            </p>
          </div>
          <div className="col-md-7 order-md-1">
            {userData && userData.lang !== "KO" ? (
              <img
                src="./assets/images/gif/project_en.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("브랜드 채널 전문가로 변신!")}
                data-description={t(
                  "운영하고자 하는 브랜드 채널에 대한 정보만 똑똑하게 입력하면 Sdot이 브랜드 채널 전문가로 변신시켜드립니다. 필요한 채널 별로 프로젝트를 등록하고 SNS 채널 운영을 쉽게 시작해보세요!"
                )}
                style={{ width: "100%" }}
              />
            ) : userLocalLang.startsWith("ko") ? (
              <img
                src="./assets/images/gif/project2.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("브랜드 채널 전문가로 변신!")}
                data-description={t(
                  "운영하고자 하는 브랜드 채널에 대한 정보만 똑똑하게 입력하면 Sdot이 브랜드 채널 전문가로 변신시켜드립니다. 필요한 채널 별로 프로젝트를 등록하고 SNS 채널 운영을 쉽게 시작해보세요!"
                )}
                style={{ width: "100%" }}
              />
            ) : (
              <img
                src="./assets/images/gif/project2_en.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("브랜드 채널 전문가로 변신!")}
                data-description={t(
                  "운영하고자 하는 브랜드 채널에 대한 정보만 똑똑하게 입력하면 Sdot이 브랜드 채널 전문가로 변신시켜드립니다. 필요한 채널 별로 프로젝트를 등록하고 SNS 채널 운영을 쉽게 시작해보세요!"
                )}
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>

        <hr className="featurette-divider" />

        <div className="row featurette pb-5">
          <div className="col-md-5">
            <h3 className="featurette-heading fw-normal lh-1">
              {t("채널만 선택하면 콘텐츠 초안 완성!")}
            </h3>
            <p className="lead">
              {t(
                "콘텐츠 주제를 입력한 후 채널만 선택하면 Sdot이 채널에 맞는 콘텐츠 초안을 생성해드립니다. 인스타그램, 페이스북, 링크드인, 숏폼 등 SNS 채널별 특색에 맞는 콘텐츠 초안을 확인해보세요!"
              )}
            </p>
          </div>
          <div className="col-md-7">
            {userData && userData.lang !== "KO" ? (
              <img
                src="./assets/images/gif/card_news2_en.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("채널만 선택하면 콘텐츠 초안 완성!")}
                data-description={t(
                  "콘텐츠 주제를 입력한 후 채널만 선택하면 Sdot이 채널에 맞는 콘텐츠 초안을 생성해드립니다. 인스타그램, 페이스북, 링크드인, 숏폼 등 SNS 채널별 특색에 맞는 콘텐츠 초안을 확인해보세요!"
                )}
                style={{ width: "100%" }}
              />
            ) : userLocalLang.startsWith("ko") ? (
              <img
                src="./assets/images/gif/card_news2.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("채널만 선택하면 콘텐츠 초안 완성!")}
                data-description={t(
                  "콘텐츠 주제를 입력한 후 채널만 선택하면 Sdot이 채널에 맞는 콘텐츠 초안을 생성해드립니다. 인스타그램, 페이스북, 링크드인, 숏폼 등 SNS 채널별 특색에 맞는 콘텐츠 초안을 확인해보세요!"
                )}
                style={{ width: "100%" }}
              />
            ) : (
              <img
                src="./assets/images/gif/card_news_en.gif"
                alt=""
                className="image-gif glightbox"
                data-title={t("채널만 선택하면 콘텐츠 초안 완성!")}
                data-description={t(
                  "콘텐츠 주제를 입력한 후 채널만 선택하면 Sdot이 채널에 맞는 콘텐츠 초안을 생성해드립니다. 인스타그램, 페이스북, 링크드인, 숏폼 등 SNS 채널별 특색에 맞는 콘텐츠 초안을 확인해보세요!"
                )}
                style={{ width: "100%" }}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
