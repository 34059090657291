function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="border-top">
      <div className="container">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
          <p className="col-md-4 mb-0 text-body-secondary">
            © {currentYear} WondersLab, Inc
          </p>

          <img
            src="./assets/images/sdot-logo.png"
            alt="logo"
            className="logo"
          />

          <ul className="nav col-md-4 justify-content-end">
            <li className="nav-item">
              <a
                href="https://www.wonderslab.ai"
                target="_blank"
                className="nav-link px-2 text-body-secondary"
              >
                WondersLab
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://wdot.wonderslab.ai"
                target="_blank"
                className="nav-link px-2 text-body-secondary"
              >
                Wdot
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://idot.wonderslab.ai"
                target="_blank"
                className="nav-link px-2 text-body-secondary"
              >
                Idot
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
