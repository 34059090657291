import "./App.css";

import React, { useState } from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import Work from "./pages/Work";
import UserList from "./pages/UserList";
import UserDetail from "./pages/UserDetail";
import CompanyList from "./pages/CompanyList";
import CompanyInsert from "./pages/CompanyInsert";
import CompanyDetail from "./pages/CompanyDetail";
import PageNotFound from "./pages/404";
import NotAuthorized from "./pages/401";
import InternalServerError from "./pages/500";
import AccountSession from "./pages/AccountSession";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

import "./locales/i18n";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/work" element={<Work />} />
            <Route path="/userlist" element={<UserList />} />
            <Route path="/userdetail" element={<UserDetail />} />
            <Route path="/companylist" element={<CompanyList />} />
            <Route path="/companyinsert" element={<CompanyInsert />} />
            <Route path="/companydetail" element={<CompanyDetail />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/401" element={<NotAuthorized />} />
            <Route path="/500" element={<InternalServerError />} />
            <Route path="/account-session" element={<AccountSession />} />
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
