import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $put,
  $delete,
  $get,
  $dateTimezone,
  $convertNumberFormat,
} from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function CompanyInsert() {
  const appUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, tokenData, checkLogin, userData } = useAuth();

  const [company, setCompany] = useState({
    company_name: "",
    business_no: "",
    country: "",
    person_in_charge: "",
    tel: "",
    email: "",
    active_yn: "Y",
  });
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [point, setPoint] = useState(0);

  const scrollRef = useRef(null);

  const getLocalDateTime = (utcDate) => {
    const date = new Date(utcDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
      2,
      "0"
    )}T${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
  };

  const saveCompany = async () => {
    setLoading(true);

    const tempCompany = { ...company };

    if (company.active_yn === "N") {
      tempCompany.active_start_date = null;
      tempCompany.active_end_date = null;
    } else {
      if (company.active_start_date) {
        tempCompany.active_start_date = new Date(company.active_start_date)
          .toISOString()
          .substring(0, 19)
          .replace("T", " ");
      } else {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "활성 기간 시작일을 입력해주세요",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return;
      }

      if (company.active_end_date) {
        tempCompany.active_end_date = new Date(company.active_end_date)
          .toISOString()
          .substring(0, 19)
          .replace("T", " ");
      } else {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "활성 기간 종료일을 입력해주세요",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return;
      }
    }

    const res = await $post(`/api/company`, tempCompany);
    if (res.status === 200) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "저장 성공",
        text: "",
        showConfirmButton: false,
        timer: 1000,
      });
      navigate("/companylist");
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: "저장 실패",
        text: res.message,
        showConfirmButton: false,
        timer: 1000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userData && userData.role_level !== 0) {
      navigate("/401");
    }
  }, [userData]);

  useEffect(() => {}, []);

  return (
    <main className="container py-3">
      <div className="content">
        <div class="d-flex justify-content-between mb-3">
          <h3>{t("기업 정보")}</h3>
          <button
            className="btn btn-primary"
            onClick={() => navigate("/companylist")}
          >
            {t("목록")}
          </button>
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">{t("기업명")}</span>
          <input
            type="text"
            className="form-control"
            value={company.company_name}
            onChange={(e) => {
              setCompany({ ...company, company_name: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">{t("비즈니스번호")}</span>
          <input
            type="text"
            className="form-control"
            value={company.business_no}
            onChange={(e) => {
              setCompany({ ...company, business_no: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">{t("국가")}</span>
          <input
            type="text"
            className="form-control"
            value={company.country}
            onChange={(e) => {
              setCompany({ ...company, country: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">{t("담당자")}</span>
          <input
            type="text"
            className="form-control"
            value={company.person_in_charge}
            onChange={(e) => {
              setCompany({ ...company, person_in_charge: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">{t("연락처")}</span>
          <input
            type="text"
            className="form-control"
            value={company.tel}
            onChange={(e) => {
              setCompany({ ...company, tel: e.target.value });
            }}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">{t("이메일")}</span>
          <input
            type="text"
            className="form-control"
            value={company.email}
            onChange={(e) => {
              setCompany({ ...company, email: e.target.value });
            }}
          />
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text">{t("상태")}</span>
          <select
            className="form-select mr-5"
            style={{ width: "100px" }}
            value={company.active_yn}
            onChange={(e) =>
              setCompany({ ...company, active_yn: e.target.value })
            }
          >
            <option value="Y">{t("활성")}</option>
            <option value="N">{t("비활성")}</option>
          </select>
        </div>

        {company.active_yn === "Y" && (
          <div className="input-group mb-3">
            <span className="input-group-text">{t("활성 기간")}</span>
            <input
              type="datetime-local"
              className="form-control"
              value={company.active_start_date}
              onChange={(e) => {
                setCompany({
                  ...company,
                  active_start_date: e.target.value,
                });
              }}
            />
            ~
            <input
              type="datetime-local"
              className="form-control"
              value={company.active_end_date}
              onChange={(e) => {
                console.log("e.target.value", e.target.value);
                setCompany({
                  ...company,
                  active_end_date: e.target.value,
                });
              }}
            />
          </div>
        )}
        <div className="flex justify-content-center">
          <button
            className="btn btn-primary btn-lg"
            onClick={() => saveCompany()}
          >
            저장
          </button>
        </div>
      </div>

      {loading && <Loading />}
    </main>
  );
}

export default CompanyInsert;
