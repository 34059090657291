import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import { $get, $post, $put, $delete, $upload } from "../utils/common";
import { useAuth } from "../login/AuthProvider";
import { $post, $put, $delete, $get, $dateTimezone } from "../utils/common";
// import WorkspaceData from "./Workspace.json";
import Swal from "sweetalert2";
import Loading from "../component/Loading";
import { useTranslation } from "react-i18next";

function AccountSession() {
  const navigate = useNavigate();
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sessionList, setSessionList] = useState([]);
  const getSessionList = async () => {
    const res = await $get(`/api/account/session`);
    if (res.status === 200) {
      if (res.data.length === 0) {
        Swal.fire({
          title: t("세션 없음"),
          text: t("현재 활성화된 세션이 없습니다."),
          iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          confirmButtonText: t("확인"),
        });
        navigate("/");
      } else {
        setSessionList(res.data);
      }
    }
  };

  const deleteOtherSession = async () => {
    Swal.fire({
      title: t("다른 세션 모두 종료하기"),
      text: t("모든 세션을 종료하시겠습니까?"),
      iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      showCancelButton: true,
      confirmButtonText: t("네, 종료합니다."),
      cancelButtonText: t("취소"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $delete(`/api/account/session`);
        if (res.status === 200) {
          navigate("/");
        }
      }
    });
  };

  useEffect(() => {
    getSessionList();
  }, []);
  return (
    <div className="container mt-3">
      <div className="content">
        <div className="content-body align-items-start">
          <div className="d-flex justify-content-between mb-2">
            <h3>{t("세션 관리")}</h3>
            <button
              className="btn btn-outline-dark"
              onClick={() => navigate("/")}
            >
              {t("홈으로 이동")}
            </button>
          </div>
          {sessionList.map((session, index) => (
            <div className="card mb-3" key={index}>
              <div className="card-header text-center">
                <h4 className="card-title">{session.ip_address}</h4>
                {index === 0 && sessionList.length > 1 ? (
                  <div className="text-center">
                    <p className="badge bg-primary">{t("현재 사용 세션")}</p>
                    <p>
                      {t("세션이 중복 사용중입니다. 세션을 모두 종료하세요.")}
                    </p>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteOtherSession()}
                    >
                      {t("다른 세션 모두 종료하기")}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="card-body">
                <p>
                  <b>User-Agent</b>: {session.user_agent}
                </p>
                <p>
                  <b>Signed in</b>: {$dateTimezone(session.create_datetime)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {loading && <Loading />}
    </div>
  );
}

export default AccountSession;
