import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import {
  $post,
  $get,
  $delete,
  $convertMarkdownToHtml,
  $dateTimezone,
  $localDateToUTCDate,
} from "../utils/common";
import { useTranslation } from "react-i18next";
import Loading from "../component/Loading";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import ProjectInfoModal from "../component/ProjectInfoModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

function Work() {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { isLoggedIn, userData, login, logout, checkLogin } = useAuth();
  const [loading, setLoading] = useState(false);
  const [beatLoading, setBeatLoading] = useState(false);
  const [beatLoadingTopic, setBeatLoadingTopic] = useState(false);
  const [beatLoadingSubTopic, setBeatLoadingSubTopic] = useState(false);
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [requestText, setRequestText] = useState("");
  const [assistantMessage, setAssistantMessage] = useState("");
  const [assistantMessage2, setAssistantMessage2] = useState("");
  const [showProjectInfoModal, setShowProjectInfoModal] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [postList, setPostList] = useState([]);
  const [activeInstagram, setActiveInstagram] = useState(true);
  const [activeFacebook, setActiveFacebook] = useState(true);
  const [activeLinkedIn, setActiveLinkedIn] = useState(true);
  const [activeShortform, setActiveShortform] = useState(true);
  const [showAssistant, setShowAssistant] = useState(false);
  const [showAssistant2, setShowAssistant2] = useState(false);
  const [totalPostCount, setTotalPostCount] = useState(0);
  const [mainTopicList, setMainTopicList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [mainTopic, setMainTopic] = useState("");
  const [toneActivateUserList, setToneActivateUserList] = useState([]);
  const [inputType, setInputType] = useState("input");
  const btnProjectModalRef = useRef();
  const btnLanguageModalRef = useRef();
  const btnToneModalRef = useRef();
  const inputRef = useRef();

  const generatePost = async (postType, sec) => {
    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);
    setShowAssistant(true);

    let url = `${serverUrl}/api/prompt/request`;
    // let url = `${serverUrl}/api/prompt/claude`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        requestText: requestText,
        projectId: activeProjectId,
        postType: postType,
        sec: sec,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);
    setRequestText("");

    insertPost(
      activeProjectId,
      postType,
      $convertMarkdownToHtml(tempAssistantMessage.join(""))
    );

    setAssistantMessage("");
    setShowAssistant(false);

    return true;
  };

  const generatePostByYoutube = async (postType, sec) => {
    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);
    setShowAssistant(true);

    let url = `${serverUrl}/api/prompt/youtube`;
    // let url = `${serverUrl}/api/prompt/claude`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        url: requestText,
        projectId: activeProjectId,
        postType: postType,
        sec: sec,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);
    setRequestText("");

    insertPost(
      activeProjectId,
      postType,
      $convertMarkdownToHtml(tempAssistantMessage.join(""))
    );

    setAssistantMessage("");
    setShowAssistant(false);

    return true;
  };

  const generatePostByLink = async (postType, sec) => {
    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);
    setShowAssistant(true);

    let url = `${serverUrl}/api/prompt/link`;
    // let url = `${serverUrl}/api/prompt/claude`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        url: requestText,
        projectId: activeProjectId,
        postType: postType,
        sec: sec,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);
    setRequestText("");

    insertPost(
      activeProjectId,
      postType,
      $convertMarkdownToHtml(tempAssistantMessage.join(""))
    );

    setAssistantMessage("");
    setShowAssistant(false);

    return true;
  };

  const sendPrompt = async (type, sec) => {
    const bChecked = await checkLogin();
    if (!bChecked) {
      return;
    }

    if (beatLoading) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t(
          "실행 중인 작업이 있습니다. 실행 중인 작업이 완료된 후 사용하세요."
        ),
        showConfirmButton: false,
        timer: 1500,
      });
    }

    if (activeProjectId === "" || activeProjectId === null) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("프로젝트를 등록하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (requestText === "") {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("피드의 주제 또는 핵심 키워드를 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (inputType === "input") {
      // requestText가 url이면 안됨.
      // const urlRegexp =
      //   /^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.?)+([a-zA-Z0-9-]+)?(\/[a-zA-Z0-9-]+?)*\/?(\?[a-zA-Z0-9-]+=[a-zA-Z0-9-]+)?/;
      // if (urlRegexp.test(requestText)) {
      //   Swal.fire({
      //     iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      //     title: t("올바른 주제 또는 키워드를 입력하세요."),
      //     showConfirmButton: false,
      //     timer: 1000,
      //   });
      //   return;
      // }

      if (type === "image") {
        await createImage("illustration", sec);
      } else {
        await generatePost(type, sec);
      }
    } else if (inputType === "youtube") {
      // requestText가 유튜브 주소인지 확인
      const youtubeRegexp =
        /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
      if (!youtubeRegexp.test(requestText)) {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: t("올바른 유튜브 주소를 입력하세요."),
          showConfirmButton: false,
          timer: 1000,
        });
        return;
      }

      await generatePostByYoutube(type, sec);
      // await createImage("youtube", sec);
    } else if (inputType === "link") {
      const urlRegexp =
        /^(http(s)?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.?)+([a-zA-Z0-9-]+)?(\/[a-zA-Z0-9-]+?)*\/?(\?[a-zA-Z0-9-]+=[a-zA-Z0-9-]+)?/;
      if (!urlRegexp.test(requestText)) {
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: t("올바른 URL을 입력하세요."),
          showConfirmButton: false,
          timer: 1000,
        });
        return;
      }

      await generatePostByLink(type, sec);
    } else if (inputType === "reference") {
      await generatePost(type, sec);
    }
  };

  const createImage = async (imageType, imageSize) => {
    // Swal progress
    Swal.fire({
      title: t("이미지 생성 중..."),
      iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      timer: 50000,
      timerProgressBar: true,
      didOpen: async () => {
        Swal.showLoading();

        const res = await $post("/api/prompt/dalle", {
          requestText,
          imageType: imageType,
          imageSize: imageSize,
        });

        const res2 = await $post("/api/upload/image-from-url", {
          imageURL: res.data.url,
        });

        // console.log(res2);

        insertPost(
          activeProjectId,
          "image",
          $convertMarkdownToHtml(
            `<img src="${res2.data.url}" style="width:100%;height:auto;" />`
          )
        );

        Swal.hideLoading();
        Swal.close();
      },
    });
  };

  const sendPromptWith = async (postId, requestType) => {
    const bChecked = await checkLogin();
    if (!bChecked) {
      return;
    }

    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);
    setShowAssistant(true);

    const postType = postList.find((post) => post.id === postId).post_type;
    const postContent = postList.find((post) => post.id === postId).content;

    let url = `${serverUrl}/api/prompt/request-with`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        projectId: activeProjectId,
        postId: postId,
        requestType: requestType,
        postType: postType,
        postContent: postContent,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);
    setRequestText("");

    insertPost(
      activeProjectId,
      postType,
      $convertMarkdownToHtml(tempAssistantMessage.join(""))
    );

    setAssistantMessage("");
    setShowAssistant(false);

    return true;
  };

  const sendPromptWithTone = async (postId, requestType, useLLM = "") => {
    const bChecked = await checkLogin();
    if (!bChecked) {
      return;
    }

    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);
    setShowAssistant(true);

    const postType = postList.find((post) => post.id === postId).post_type;
    const postContent = postList.find((post) => post.id === postId).content;

    let url = `${serverUrl}/api/prompt/tone${useLLM}`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        projectId: activeProjectId,
        postId: postId,
        requestType: requestType,
        postType: postType,
        postContent: postContent,
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);
    setRequestText("");

    insertPost(
      activeProjectId,
      postType,
      $convertMarkdownToHtml(tempAssistantMessage.join(""))
    );

    setAssistantMessage("");
    setShowAssistant(false);

    return true;
  };

  const insertPost = async (projectId, postType, postContent) => {
    const r = await $post("/api/post", {
      project_id: projectId,
      post_type: postType,
      content: postContent,
    });
    if (r.status === 200) {
      getTotalPostCount();
      const postId = r.data.insertId;
      const postDetail = await $get(`/api/post/${postId}`);
      if (postDetail.status === 200) {
        setPostList([postDetail.data, ...postList]);
        getTotalPostCount();
      }
    }
  };

  const deletePost = async (postId) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: t("정말 삭제하시겠습니까?"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("네, 삭제할게요."),
      cancelButtonText: t("취소"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const r = await $delete(`/api/post/${postId}`);
        if (r.status === 200) {
          const newPostList = postList.filter((post) => post.id !== postId);
          setPostList(newPostList);
          getTotalPostCount();
        }
      }
    });
  };

  const getTotalPostCount = async () => {
    const res = await $get(`/api/post/count/${activeProjectId}`);
    if (res.status === 200) {
      setTotalPostCount(res.data.totalCount);
    }
  };

  const getSubPrompt = async () => {
    setAssistantMessage("");

    setBeatLoading(true);
    sessionStorage.setItem("stopAssistant", false);
    setShowAssistant(true);

    let url = `${serverUrl}/api/prompt/request`;

    const res = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        requestText: requestText,
        projectId: activeProjectId,
        postType: "instagram",
      }),
    });

    const reader = res.body.pipeThrough(new TextDecoderStream()).getReader();
    const tempAssistantMessage = [];
    const finishRegexp = /(\[\[.*?\]\])/;
    let finishReason = "";
    while (true) {
      if (sessionStorage.getItem("stopAssistant") === "true") {
        reader.cancel();
        reader.releaseLock();
        break;
      }

      let { value, done } = await reader.read();
      if (done) break;

      if (finishRegexp.test(value)) {
        finishReason = value.match(finishRegexp)[0];
        value = "";
      }

      tempAssistantMessage.push(value);

      value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
      setAssistantMessage2((prev) => prev + value);
    }

    sessionStorage.setItem("stopAssistant", true);
    setBeatLoading(false);
    setRequestText("");

    setAssistantMessage2($convertMarkdownToHtml(tempAssistantMessage.join("")));
  };

  const stopGenerating = () => {
    sessionStorage.setItem("stopAssistant", true);
  };

  const getPostList = async () => {
    const res = await $get(`/api/post/list/${activeProjectId}`);
    if (res.status === 200) {
      setMainTopicList([]);
      setSubTopicList([]);
      getMainTopic();
      getTotalPostCount();
      setPostList(res.data);
    }
  };

  const loadMore = async () => {
    if (activeProjectId === "" || activeProjectId === null) return;
    if (postList.length === 0) return;

    const res = await $get(
      `/api/post/more/${activeProjectId}/${postList[postList.length - 1].id}`
    );
    if (res.status === 200) {
      if (res.data.length > 0) {
        setPostList([...postList, ...res.data]);
      }
    }
  };

  // const getTopic = async () => {
  //   const res = await $get(`/api/prompt/topic`);
  // };

  const generateMainTopic = async () => {
    setBeatLoadingTopic(true);
    setMainTopicList([]);
    const res = await $post(`/api/prompt/main-topic`, {
      projectId: activeProjectId,
    });
    if (res.status === 200) {
      setMainTopicList(res.data);
    } else {
      setMainTopicList([]);
    }
    setSubTopicList([]);
    setBeatLoadingTopic(false);
  };

  const getMainTopic = async () => {
    setBeatLoadingTopic(true);
    setMainTopicList([]);
    const topic = await $get(
      `/api/topic/${activeProjectId}/${$localDateToUTCDate()}`
    );
    if (topic.status === 200 && topic.data.length > 0) {
      setMainTopicList(topic.data);
    } else {
      await generateMainTopic();
    }
    setSubTopicList([]);
    setBeatLoadingTopic(false);
  };

  const getSubTopic = async () => {
    setBeatLoadingSubTopic(true);
    setSubTopicList([]);
    const res = await $post(`/api/prompt/sub-topic`, {
      projectId: activeProjectId,
      mainTopic: mainTopic,
    });
    if (res.status === 200) {
      setSubTopicList(res.data);
    } else {
      setSubTopicList([]);
    }
    setBeatLoadingSubTopic(false);
  };

  const checkRemainActiveDate = () => {
    if (userData.active_end_date) {
      const currentDate = new Date();
      const activeEndDate = new Date(userData.active_end_date);
      if (currentDate < activeEndDate) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (mainTopic !== "") {
      getSubTopic();
    }
  }, [mainTopic]);

  const deleteProject = async (projectId) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: t("정말 삭제하시겠습니까?"),
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: t("네, 삭제할게요."),
      cancelButtonText: t("취소"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const r = await $delete(`/api/project/${projectId}`);
        if (r.status === 200) {
          getProjectList();
        }
      }
    });
  };

  const getProjectList = async () => {
    const res = await $get(`/api/project`);
    if (res.status === 200) {
      setProjectList(res.data);
      if (res.data.length > 0) {
        setActiveProjectId(res.data[0].id);
      } else {
        setActiveProjectId(null);
        setPostList([]);
        setMainTopicList([]);
        setSubTopicList([]);
        setRequestText("");
        setSelectedProject(null);
        setSelectedProjectId(null);
      }
    }
  };

  // const getToneActivateUserList = async () => {
  //   const res = await $get(`/api/user/tone-activate`);
  //   if (res.status === 200) {
  //     setToneActivateUserList(res.data);
  //   }
  // };

  // useEffect(() => {
  //   if (btnProjectModalRef.current && selectedProjectId !== -1) {
  //     btnProjectModalRef.current.click();
  //   }
  // }, [selectedProjectId]);

  useEffect(() => {
    setRequestText("");
  }, [inputType]);

  useEffect(() => {
    if (activeProjectId !== "" && activeProjectId !== null) {
      setMainTopic("");
      getPostList();
    }
  }, [activeProjectId]);

  useEffect(() => {
    if (userData.active_yn === "N") {
      navigate("/");
    } else {
      getProjectList();

      // getToneActivateUserList();
    }
  }, []);

  useEffect(() => {
    if (userData && inputRef.current) {
      inputRef.current.focus();
    }
  }, [userData]);

  return (
    <main style={{ minHeight: "800px" }}>
      {userData && userData.active_yn === "Y" && (
        <div className="container py-4">
          <div className="row g-4">
            <div className="col-lg-3">
              <div className="d-flex align-items-center d-lg-none">
                <button
                  className="border-0 bg-transparent"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasSideNavbar"
                  aria-controls="offcanvasSideNavbar"
                >
                  <span className="btn btn-primary">
                    <i className="fa-solid fa-sliders-h"></i>
                  </span>
                  <span className="h6 mb-0 fw-bold d-lg-none ms-2">
                    {t("내 프로젝트")}
                  </span>
                </button>
              </div>
              <nav
                className="navbar navbar-expand-lg mx-0"
                style={{ paddingTop: "0px" }}
              >
                <div
                  className="offcanvas offcanvas-start"
                  id="offcanvasSideNavbar"
                >
                  <div className="offcanvas-header">
                    <button
                      type="button"
                      className="btn-close text-reset ms-auto"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="offcanvas-body d-block px-2 px-lg-0">
                    <div className="card overflow-hidden">
                      <div
                        className="h-50px bg-center-cover"
                        style={{
                          backgroundImage: "url(./assets/images/bg/01.jpg)",
                        }}
                      ></div>
                      <div className="card-body pt-0">
                        <div className="text-center">
                          <div className="avatar avatar-lg mt-n5 mb-3">
                            <a onClick={() => navigate("/work")}>
                              <img
                                className="avatar-img rounded border border-white border-3"
                                src={
                                  userData.profile_image_url
                                    ? userData.profile_image_url
                                    : "./assets/images/avatar/placeholder.jpg"
                                }
                                alt=""
                              />
                            </a>
                          </div>
                          <h5 className="mb-0">
                            {" "}
                            <a href="#!">{userData.nickname} </a>{" "}
                          </h5>
                          {/* <small>WondersLab</small> */}
                        </div>
                        <hr />

                        <ul className="nav nav-link-secondary flex-column fw-bold gap-2">
                          {projectList.map((project) => (
                            <li
                              className="nav-item"
                              style={{ display: "flex", alignItems: "center" }}
                              key={`project-${project.id}`}
                            >
                              <a
                                className="nav-link"
                                onClick={() => {
                                  setActiveProjectId(project.id);
                                  // Swal project.project_title이 선택되었습니다.
                                  Swal.fire({
                                    iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                                    title: `${project.project_title} ${t(
                                      "프로젝트가 선택되었습니다."
                                    )}`,
                                    showConfirmButton: false,
                                    showCancelButton: false,
                                    timer: 1000,
                                  });
                                }}
                              >
                                {/* <i className="fa-regular fa-circle-dot me-1"></i> */}
                                <span>{project.project_title}</span>
                              </a>
                              <button
                                className="btn btn-outline-primary btn-sm me-1"
                                onClick={async () => {
                                  const bChecked = await checkLogin();
                                  if (bChecked) {
                                    setSelectedProject(project);
                                    setSelectedProjectId(project.id);
                                    btnProjectModalRef.current.click();
                                  }
                                }}
                              >
                                <i className="fa-regular fa-pen-to-square"></i>
                              </button>
                              <button
                                className="btn btn-outline-danger btn-sm"
                                onClick={async () => {
                                  const bChecked = await checkLogin();
                                  if (bChecked) {
                                    deleteProject(project.id);
                                  }
                                }}
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="card-footer text-center py-2">
                        <button
                          className="btn btn-primary"
                          ref={btnProjectModalRef}
                          data-bs-toggle="modal"
                          data-bs-target="#projectInfoModal"
                          style={{ display: "none" }}
                        ></button>
                        <a
                          className="btn btn-dark d-md-block mt-1"
                          onClick={async () => {
                            const bChecked = await checkLogin();
                            if (bChecked) {
                              setSelectedProjectId(null);
                              setSelectedProject(null);
                              // setTimeout(() => {
                              btnProjectModalRef.current.click();
                              // }, 600);
                            }
                          }}
                        >
                          <i className="fa fa-plus"></i>{" "}
                          {t("프로젝트 등록하기")}
                        </a>
                      </div>
                      {/* {userData && userData.lang === "KO" ? (
                        <a
                          href="https://wondersaiguide.oopy.io/924c3a36-d728-4446-a923-82af0264b1fb"
                          target="_blank"
                          className="btn btn-link btn-sm"
                        >
                          {t("사용자 가이드")}
                        </a>
                      ) : (
                        <a
                          href="https://wondersaiguideen.oopy.io/3e41302a-b5fd-4c0c-8a9b-5abacfabb038"
                          target="_blank"
                          className="btn btn-link btn-sm"
                        >
                          {t("사용자 가이드")}
                        </a>
                      )} */}
                    </div>
                  </div>
                  {checkRemainActiveDate() && (
                    <div className="mt-2">
                      {userData.subscription_yn === "N" && (
                        <p className="text-center">
                          {t("사용 만료일")} :{" "}
                          {new Date(userData.active_end_date).toLocaleString()}
                        </p>
                      )}
                    </div>
                  )}

                  {/* {userData && userData.subscription_yn === "N" && (
                    <a
                      href="https://www.wonderslab.ai/pricing"
                      target="_blank"
                      className="btn btn-danger d-md-block mb-1"
                    >
                      {t("구독하기")}
                    </a>
                  )} */}
                </div>
              </nav>
            </div>
            {activeProjectId === "" || activeProjectId === null ? (
              <div className="col-md-8 col-lg-6 vstack gap-4 order-lg-1 order-2">
                <div className="card card-body">
                  {/* 프로젝트를 등록하세요. */}
                  <div className="text-center">
                    <img
                      src="./assets/images/sdot-logo.png"
                      alt="Sdot"
                      style={{ width: "100px" }}
                    />
                    <h3 className="mt-3">{t("프로젝트를 등록하세요.")}</h3>
                    {/* <p>
                    프로젝트를 등록하시면 Sdot이 자동으로 피드를 생성해드립니다.
                  </p> */}
                    <button
                      className="btn btn-outline-danger"
                      onClick={async () => {
                        const bChecked = await checkLogin();
                        if (bChecked) {
                          setSelectedProject(null);
                          setSelectedProjectId(null);
                          btnProjectModalRef.current.click();
                        }
                      }}
                    >
                      {t("프로젝트 등록하기")}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-8 col-lg-6 vstack gap-4 order-lg-1 order-2">
                <div className="card card-body">
                  <div className="d-flex mb-3">
                    <select
                      className="form-select"
                      value={inputType}
                      onChange={(e) => setInputType(e.target.value)}
                    >
                      <option value="input"> {t("주제 입력")} </option>
                      {/* <option value="youtube"> {t("유튜브 주소")} </option> */}
                      <option value="link"> {t("웹페이지 주소")} </option>
                      <option value="reference">{t("참고할 내용 입력")}</option>
                    </select>
                  </div>
                  <div className="d-flex mb-3">
                    {/* <div className="avatar avatar-xs me-2">
                      <a href="#">
                        {" "}
                        <img
                          className="avatar-img rounded-circle"
                          src="assets/images/avatar/placeholder.jpg"
                          alt=""
                        />{" "}
                      </a>
                    </div> */}
                    {/* <div>
                      <select
                        className="form-control"
                        value={inputType}
                        onChange={(e) => setInputType(e.target.value)}
                      >
                        <option value="input"> {t("주제입력")} </option>
                        <option value="youtube"> {t("유튜브주소")} </option>
                      </select>
                    </div> */}
                    <div className="w-100">
                      {inputType !== "reference" ? (
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t(
                            inputType === "input"
                              ? "피드의 주제, 핵심 키워드를 입력하세요."
                              : inputType === "youtube"
                              ? "유튜브 주소를 입력하세요."
                              : inputType === "link"
                              ? "웹페이지 주소를 입력하세요."
                              : "참고할 내용을 입력하세요."
                          )}
                          value={requestText}
                          onChange={(e) => setRequestText(e.target.value)}
                          ref={inputRef}
                        />
                      ) : (
                        <textarea
                          className="form-control"
                          placeholder={t("참고할 내용을 입력하세요.")}
                          rows="5"
                          value={requestText}
                          onChange={(e) => setRequestText(e.target.value)}
                          ref={inputRef}
                        ></textarea>
                      )}
                    </div>
                  </div>
                  <ul className="nav nav-pills nav-stack small fw-normal">
                    <li className="nav-item">
                      <a
                        className={
                          activeInstagram
                            ? "nav-link bg-danger text-white py-1 px-2 mb-0"
                            : "nav-link bg-light py-1 px-2 mb-0"
                        }
                        onClick={() => sendPrompt("instagram")}
                      >
                        {" "}
                        <i className="fa-brands fa-square-instagram me-1"></i>
                        {t("인스타그램")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeFacebook
                            ? "nav-link bg-danger text-white py-1 px-2 mb-0"
                            : "nav-link bg-light py-1 px-2 mb-0"
                        }
                        onClick={() => sendPrompt("facebook")}
                      >
                        {" "}
                        <i className="fa-brands fa-square-facebook me-1"></i>
                        {t("페이스북")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeLinkedIn
                            ? "nav-link bg-danger text-white py-1 px-2 mb-0"
                            : "nav-link bg-light py-1 px-2 mb-0"
                        }
                        onClick={() => sendPrompt("linkedin")}
                      >
                        {" "}
                        <i className="fa-brands fa-linkedin me-1"></i>
                        {t("링크드인")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeShortform
                            ? "nav-link bg-danger text-white py-1 px-2 mb-0"
                            : "nav-link bg-light py-1 px-2 mb-0"
                        }
                        onClick={() => {
                          if (requestText === "") {
                            Swal.fire({
                              iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                              title: t(
                                "피드의 주제 또는 핵심 키워드를 입력하세요."
                              ),
                              showConfirmButton: false,
                              timer: 1000,
                            });
                            return;
                          }

                          Swal.fire({
                            iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                            title: t("숏폼 길이 설정(단위:초)"),
                            text: t("20~90 사이의 숫자를 입력하세요."),
                            input: "number",
                            inputValue: 30,
                            showCancelButton: true,
                            confirmButtonText: "확인",
                            cancelButtonText: "취소",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              if (result.value < 20 || result.value > 90) {
                                Swal.fire({
                                  icon: "error",
                                  title: t("숫자 입력 오류"),
                                  text: t("20~90 사이의 숫자를 입력하세요."),
                                });
                                return;
                              }
                              sendPrompt("shortform", result.value);
                            }
                          });
                        }}
                      >
                        {" "}
                        <i className="fa-solid fa-video me-1"></i>
                        {t("숏폼")}1
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          activeShortform
                            ? "nav-link bg-danger text-white py-1 px-2 mb-0"
                            : "nav-link bg-light py-1 px-2 mb-0"
                        }
                        onClick={() => {
                          if (requestText === "") {
                            Swal.fire({
                              iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                              title: t(
                                "피드의 주제 또는 핵심 키워드를 입력하세요."
                              ),
                              showConfirmButton: false,
                              timer: 1000,
                            });
                            return;
                          }

                          Swal.fire({
                            iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                            title: t("[촬영용] 숏폼 길이 설정(단위:초)"),
                            text: t("20~90 사이의 숫자를 입력하세요."),
                            input: "number",
                            inputValue: 30,
                            showCancelButton: true,
                            confirmButtonText: "확인",
                            cancelButtonText: "취소",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              if (result.value < 20 || result.value > 90) {
                                Swal.fire({
                                  icon: "error",
                                  title: t("숫자 입력 오류"),
                                  text: t("20~90 사이의 숫자를 입력하세요."),
                                });
                                return;
                              }
                              sendPrompt("video", result.value);
                            }
                          });
                        }}
                      >
                        {" "}
                        <i className="fa-solid fa-video me-1"></i>
                        {t("숏폼")}2
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      style={{ display: inputType === "input" ? "" : "none" }}
                    >
                      <a
                        className={
                          activeLinkedIn
                            ? "nav-link bg-danger text-white py-1 px-2 mb-0"
                            : "nav-link bg-light py-1 px-2 mb-0"
                        }
                        onClick={() => {
                          if (requestText === "") {
                            Swal.fire({
                              iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                              title: t(
                                "피드의 주제 또는 핵심 키워드를 입력하세요."
                              ),
                              showConfirmButton: false,
                              timer: 1000,
                            });
                            return;
                          }
                          // Swal.fire select box
                          Swal.fire({
                            iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                            title: t("이미지 크기 선택"),
                            input: "select",
                            inputOptions: {
                              "1024x1024": "1:1",
                              "1792x1024": "16:9",
                              "1024x1792": "9:16",
                            },
                            inputValue: "1024x1024",
                            showCancelButton: true,
                            confirmButtonText: "확인",
                            cancelButtonText: "취소",
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              createImage("illustration", result.value);
                            }
                          });
                        }}
                      >
                        {" "}
                        <i className="fa fa-image me-1"></i>
                        {t("이미지")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div
                  className="card"
                  style={{ display: showAssistant ? "block" : "none" }}
                >
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-start">
                      {beatLoading && <BeatLoader color="#f2295b" />}
                      {beatLoading && (
                        <button
                          type="button"
                          className="btn btn-transparent btn-sm"
                          onClick={() => stopGenerating()}
                        >
                          <i className="fa-regular fa-circle-stop"></i>{" "}
                          {t("Stop generating")}
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="markdown prose w-full break-words dark:prose-invert light"
                      dangerouslySetInnerHTML={{
                        __html: assistantMessage,
                      }}
                    ></div>
                  </div>
                </div>
                {postList.map((post) => (
                  <div className="card" key={post.id}>
                    <div className="card-header border-0 pb-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-story me-2">
                            <a href="#!">
                              {" "}
                              <img
                                className="avatar-img rounded-circle"
                                src={
                                  post.profile_image_url
                                    ? post.profile_image_url
                                    : "./assets/images/avatar/placeholder.jpg"
                                }
                                alt=""
                              />{" "}
                            </a>
                          </div>
                          <div>
                            <div className="nav nav-divider">
                              <h6 className="nav-item card-title mb-0">
                                {" "}
                                <a href="#!"> {userData.nickname} </a>
                              </h6>
                              <span className="nav-item small">
                                {" "}
                                {$dateTimezone(post.create_datetime)}
                              </span>
                            </div>
                            <p className="mb-0 small">{post.post_type}</p>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a
                            href="#"
                            className="btn btn-outline-danger btn-secondary-soft-hover py-1 px-2"
                            id="cardFeedAction"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i class="fa-solid fa-ellipsis"></i>
                          </a>
                          <ul
                            className="dropdown-menu dropdown-menu-end"
                            aria-labelledby="cardFeedAction"
                          >
                            {/* <li>
                            <a className="dropdown-item" href="#">
                              {" "}
                              <i className="fa-regular fa-pen-to-square me-2"></i>
                              편집하기
                            </a>
                          </li> */}

                            {/* <li>
                            <a className="dropdown-item" href="#">
                              {" "}
                              <i className="fa-solid fa-microphone-lines me-2"></i>
                              톤앤매너 바꾸기
                            </a>
                          </li> */}
                            <li
                              style={{
                                display:
                                  post.post_type === "image" ? "none" : "",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={() => sendPromptWith(post.id, "long")}
                              >
                                {" "}
                                <i className="fa-solid fa-keyboard me-2"></i>
                                {t("더길게 쓰기")}
                              </a>
                            </li>

                            <li
                              style={{
                                display:
                                  post.post_type === "image" ? "none" : "",
                              }}
                            >
                              <a
                                className="dropdown-item"
                                onClick={() => sendPromptWith(post.id, "short")}
                              >
                                {" "}
                                <i className="fa-solid fa-filter me-2"></i>
                                {t("더짧게 쓰기")}
                              </a>
                            </li>
                            {/* <li>
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  sendPromptWith(post.id, "continue")
                                }
                              >
                                {" "}
                                <i className="fa-solid fa-filter me-2"></i>
                                {t("이어쓰기")}
                              </a>
                            </li> */}
                            {/* {userData.lang === "KO" && ( */}
                            <li
                              style={{
                                display:
                                  post.post_type === "image" ? "none" : "",
                              }}
                            >
                              <button
                                className="btn"
                                ref={btnToneModalRef}
                                data-bs-toggle="modal"
                                data-bs-target="#toneModal"
                                style={{ display: "none" }}
                              ></button>
                              <a
                                className="dropdown-item"
                                onClick={async () => {
                                  const bChecked = await checkLogin();
                                  if (bChecked) {
                                    setSelectedPostId(post.id);
                                    btnToneModalRef.current.click();
                                  }
                                }}
                              >
                                {" "}
                                <i className="fa-solid fa-microphone-lines"></i>{" "}
                                {t("톤앤매너 바꾸기")}
                              </a>
                            </li>
                            {/* )} */}
                            <li
                              style={{
                                display:
                                  post.post_type === "image" ? "none" : "",
                              }}
                            >
                              <button
                                className="btn"
                                ref={btnLanguageModalRef}
                                data-bs-toggle="modal"
                                data-bs-target="#languageModal2"
                                style={{ display: "none" }}
                              ></button>
                              <a
                                className="dropdown-item"
                                onClick={async () => {
                                  const bChecked = await checkLogin();
                                  if (bChecked) {
                                    setSelectedPostId(post.id);
                                    btnLanguageModalRef.current.click();
                                  }
                                }}
                              >
                                {" "}
                                <i className="fa-solid fa-language me-2"></i>
                                {t("번역하기")}
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={async () => {
                                  const bChecked = await checkLogin();
                                  if (bChecked) {
                                    deletePost(post.id);
                                  }
                                }}
                              >
                                {" "}
                                <i className="fa-regular fa-trash-can me-2"></i>
                                {t("삭제하기")}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        className="markdown prose w-full break-words dark:prose-invert light"
                        dangerouslySetInnerHTML={{
                          __html: post.content,
                        }}
                      ></div>
                      {/* <div className="d-flex mb-3 mt-3">
                    <div className="avatar avatar-xs me-2">
                      <a href="#!">
                        {" "}
                        <img
                          className="avatar-img rounded-circle"
                          src={
                            userData.profile_image_url
                              ? userData.profile_image_url
                              : "./assets/images/avatar/placeholder.jpg"
                          }
                          alt=""
                        />{" "}
                      </a>
                    </div>
                    <div className="nav nav-item w-100 position-relative">
                      <input
                        type="text"
                        className="form-control pe-5 bg-light"
                        rows="1"
                        placeholder="Add a comment..."
                      />
                      <button
                        className="nav-link bg-transparent px-3 position-absolute top-50 end-0 translate-middle-y border-0"
                        type="button"
                      >
                        <i className="fa-solid fa-paper-plane"></i>
                      </button>
                    </div>
                  </div> */}
                      {/* <ul className="comment-wrap list-unstyled border-top pt-3">
                    <li className="comment-item">
                      <div className="d-flex position-relative">
                        <div className="avatar avatar-xs">
                          <a href="#!">
                            <img
                              className="avatar-img rounded-circle"
                              src="assets/images/avatar/avatar.jpg"
                              alt=""
                            />
                          </a>
                        </div>
                        <div className="ms-2">
                          <div className="bg-light rounded-start-top-0 p-3 rounded">
                            <div className="d-flex justify-content-between">
                              <h6 className="mb-1">
                                {" "}
                                <a href="#!"> 영어로 번역 </a>
                              </h6>
                              <small className="ms-2">5hr</small>
                            </div>
                            <div className="small mb-0"></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul> */}
                    </div>
                  </div>
                ))}
                {postList.length < totalPostCount && (
                  <a
                    onClick={() => loadMore()}
                    role="button"
                    className="btn btn-loader btn-primary-soft"
                  >
                    <span className="load-text"> Load more </span>
                    <div className="load-icon">
                      <div
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </a>
                )}
              </div>
            )}

            {activeProjectId !== "" && activeProjectId !== null && (
              <div className="col-lg-3 order-md-2 order-1">
                <div className="row g-4">
                  <div className="col-sm-6 col-lg-12">
                    <div className="card">
                      <div className="card-header pb-0 border-0 flex-row justify-content-between">
                        <h5 className="card-title mb-0 ">
                          {t("오늘의 주제")}
                          <i
                            className="fa-solid fa-rotate ms-1"
                            onClick={async () => {
                              const bChecked = await checkLogin();
                              if (bChecked) {
                                generateMainTopic();
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          ></i>

                          {/* <i
                            className="fa-solid fa-plus ms-2"
                            style={{ cursor: "pointer" }}
                          ></i> */}
                        </h5>
                      </div>

                      <div className="card-body">
                        {beatLoadingTopic && <BeatLoader color="#f2295b" />}
                        {mainTopicList.map((topic, idx) => (
                          <div className="mb-3" key={`main-topic-${idx}`}>
                            <h6 className="mb-0">
                              <a
                                onClick={() => setMainTopic(topic.topic)}
                                className={
                                  mainTopic === topic.topic
                                    ? "topic active"
                                    : "topic"
                                }
                              >
                                {topic.topic}
                              </a>
                            </h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {mainTopic !== "" && (
                    <div className="col-sm-6 col-lg-12">
                      <div className="card">
                        <div className="card-header pb-0 border-0">
                          <h5 className="card-title mb-0">
                            {t("이런 피드 어때요?")}
                            <i
                              className="fa-solid fa-rotate ms-1"
                              onClick={async () => {
                                const bChecked = await checkLogin();
                                if (bChecked) {
                                  getSubTopic();
                                }
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </h5>
                        </div>
                        <div className="card-body">
                          {beatLoadingSubTopic && (
                            <BeatLoader color="#f2295b" />
                          )}
                          {subTopicList.map((topic, idx) => (
                            <div className="mb-3" key={`sub-topic-${idx}`}>
                              <h6 className="mb-0">
                                <a
                                  onClick={() => {
                                    if (inputType === "youtube") {
                                      Swal.fire({
                                        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                                        title: t(
                                          "유튜브 주소를 선택한 상태에서는 피드를 선택할 수 없습니다."
                                        ),
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        timer: 1500,
                                      });
                                      return;
                                    }

                                    if (inputType === "link") {
                                      Swal.fire({
                                        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                                        title: t(
                                          "웹페이지 주소를 선택한 상태에서는 피드를 선택할 수 없습니다."
                                        ),
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        timer: 1500,
                                      });
                                      return;
                                    }

                                    setRequestText(topic.topic);
                                    Swal.fire({
                                      iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                                      title: t(
                                        "선택된 피드가 입력창에 복사되었습니다."
                                      ),
                                      showCancelButton: false,
                                      showConfirmButton: false,
                                      timer: 1500,
                                    });
                                  }}
                                  className="topic"
                                >
                                  {topic.topic}
                                </a>
                              </h6>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {/* <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    weekends={false}
                    // events={events}
                    headerToolbar={{
                      // left: "prev,next",
                      center: "title",
                      // right: "timeGridWeek,timeGridDay", // user can switch between the two
                      left: "",
                      // center: "",
                      right: "",
                    }}
                    allDaySlot={false}
                    height="auto"
                    width="auto"
                    // eventContent={renderEventContent}
                    // eventColor="#6851ba"
                  /> */}
                </div>
              </div>
            )}
          </div>
          <div
            className="modal fade"
            id="languageModal2"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="languageModal2Label"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="languageModal2Label">
                    {t("번역 언어")}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ textAlign: "center" }}>
                  {userData && (
                    <div className="row g-2">
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "EN")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          English(EN)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "KO")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          한국어(KO)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "JP")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          日本語(JP)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "FR")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Français(FR)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "ES")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Español(ES)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "DE")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Deutsch(DE)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "IT")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Italiano(IT)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "RU")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Русский(RU)
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() => sendPromptWith(selectedPostId, "NL")}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Nederlands(NL)
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="toneModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="toneModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="toneModalLabel">
                    {t("톤앤매너")}
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ textAlign: "center" }}>
                  {userData && (
                    <div className="row g-2">
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("병맛(B급 감성)"),
                              ""
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("병맛(B급 감성)")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("공식적인"),
                              "-gpt"
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("공식적인")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("친근한"),
                              "-gpt"
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("친근한")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("설득력있는"),
                              "-gpt"
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("설득력있는")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("전문적인"),
                              "-gpt"
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("전문적인")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("유머스러운"),
                              ""
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("유머스러운")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("반전있는"),
                              ""
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("반전있는")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("영감을주는"),
                              "-gpt"
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("영감을주는")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(
                              selectedPostId,
                              t("이야기하는듯한"),
                              "-gpt"
                            )
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("이야기하는듯한")}
                        </button>
                      </div>
                      {/* <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(selectedPostId, "경상도 사투리")
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("경상도 사투리")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(selectedPostId, "충청도 사투리")
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("충청도 사투리")}
                        </button>
                      </div>
                      <div className="col-lg-4 col-md-6 col-12">
                        <button
                          className={`btn btn-outline-dark`}
                          style={{ width: "140px" }}
                          onClick={() =>
                            sendPromptWithTone(selectedPostId, "강원도 사투리")
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          {t("강원도 사투리")}
                        </button>
                      </div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <ProjectInfoModal
        selectedProject={selectedProject}
        selectedProjectId={selectedProjectId}
        show={showProjectInfoModal}
        onList={() => {
          // setSelectedProject(null);
          // setSelectedProjectId(null);
          getProjectList();
          // projectInfoModal
          // new window.bootstrap.Modal("#projectInfoModal").hide();
        }}
      />
    </main>
  );
}

export default Work;
