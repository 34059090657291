import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../login/AuthProvider";
import { useTranslation } from "react-i18next";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { $post, $put, $get, $convertMarkdownToHtml } from "../utils/common";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";

function Header() {
  const appUrl = process.env.REACT_APP_URL;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData, login, logout } = useAuth();
  const navigate = useNavigate();
  const userLocalLang = navigator.language || navigator.userLanguage;
  const googleLoginRef = useRef();

  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const saveLanguage = async (lang) => {
    Swal.fire({
      iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
      title: t("언어를 변경하시겠습니까?"),
      text: t("자동 로그아웃 처리됩니다."),
      showCancelButton: true,
      confirmButtonText: t("네"),
      cancelButtonText: t("아니오"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await $put(`/api/user/lang`, { lang: lang });
        if (res.status === 200) {
          socialLogout();
        }
      }
    });
  };

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);
    let userLanguage = "EN";

    const res = await $post("/api/google-login", {
      // tokenId: resData.azp,
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLanguage,
    });
    // 서버로 토큰 전송

    const userInfo = res.data[0];
    // setLoading(false);

    // console.log("userInfo", userInfo);

    if (res.status === 200) {
      login({
        ...userInfo,
      });
      // setTimeout(() => {
      //   navigate("/work");
      // }, 1000);
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  const loginWithGoogle = async () => {
    let url = "https://accounts.google.com/o/oauth2/v2/auth";
    // client_id는 위 스크린샷을 보면 발급 받았음을 알 수 있음
    // 단, 스크린샷에 있는 ID가 아닌 당신이 직접 발급 받은 ID를 사용해야 함.
    url += `?client_id=${googleClientId}`;
    // 아까 등록한 redirect_uri
    // 로그인 창에서 계정을 선택하면 구글 서버가 이 redirect_uri로 redirect 시켜줌
    url += `&redirect_uri=${serverUrl}/api/google-login`;
    // 필수 옵션.
    url += "&response_type=code";
    // 구글에 등록된 유저 정보 email, profile을 가져오겠다 명시
    url += "&scope=email profile";
    // 완성된 url로 이동
    // 이 url이 위에서 본 구글 계정을 선택하는 화면임.

    // 브라우저 url을 이동
    document.location.href = url;
  };

  const logoutWithKakao = async (accessToken) => {
    // const res = (await $get("/api/login-check")).data;
    // if (res.isLoggedIn) {
    fetch("https://kapi.kakao.com/v1/user/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
        window.Kakao.Auth.setAccessToken(null);
        logout();
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    // } else {
    //   console.log("Not logged in.");
    //   return;
    // }
  };

  const socialLogout = async () => {
    const res = (await $get("/api/login-check")).data;
    if (res.isLoggedIn) {
      if (res.loginType === "KAKAO") {
        logout();
      } else if (res.loginType === "GOOGLE") {
        logout();
      } else if (res.loginType === "NAVER") {
        logout();
      }
    }
  };

  return (
    <header
      className={
        showLanguageModal
          ? "position-none p-3 border-bottom"
          : "position-sticky p-3 border-bottom"
      }
    >
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <img
            src="./assets/images/sdot-logo.png"
            alt="logo"
            className="logo"
          />

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 ms-5 justify-content-center mb-md-0">
            <li>
              <a
                onClick={() => navigate("/")}
                className="nav-link px-2"
                style={{
                  color:
                    window.location.pathname === "/"
                      ? "var(--tg-primary-main)"
                      : "",
                }}
              >
                Home
              </a>
            </li>
            {userData && userData.email && (
              <li>
                <a
                  onClick={() => {
                    if (userData && userData.active_yn === "N") {
                      Swal.fire({
                        iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
                        title: t("구독이 필요한 서비스입니다."),
                        showCancelButton: true,
                        confirmButtonText: t("구독하러 가기"),
                        cancelButtonText: t("확인"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.open(
                            "https://www.wonderslab.ai/pricing",
                            "_blank"
                          );
                        }
                      });
                    } else {
                      navigate("/work");
                    }
                  }}
                  className="nav-link fw-bold px-2"
                  style={{
                    color:
                      window.location.pathname === "/work"
                        ? "var(--tg-primary-main)"
                        : "",
                  }}
                >
                  My Work
                </a>
              </li>
            )}

            {userLocalLang.startsWith("ko") ? (
              <li>
                <a
                  href="https://wondersaiguide.oopy.io/924c3a36-d728-4446-a923-82af0264b1fb"
                  target="_blank"
                  className="nav-link px-2"
                >
                  {t("사용자 가이드")}
                </a>
              </li>
            ) : (
              <a
                href="https://wondersaiguideen.oopy.io/3e41302a-b5fd-4c0c-8a9b-5abacfabb038"
                target="_blank"
                className="nav-link px-2"
              >
                {t("사용자 가이드")}
              </a>
            )}
            {userData && userData.subscription_yn === "N" && (
              <li>
                <a
                  href="https://wonderslab.ai/pricing"
                  target="_blank"
                  className="nav-link px-2"
                >
                  {t("구독하기")}
                </a>
              </li>
            )}
          </ul>
          {isLoggedIn && (
            <div className="dropdown text-end">
              <a
                href="#"
                className="d-block link-body-emphasis text-decoration-none dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={
                    userData.profile_image_url
                      ? userData.profile_image_url
                      : "./assets/images/avatar/placeholder.jpg"
                  }
                  alt=""
                  width="32"
                  height="32"
                  className="rounded-circle"
                />
              </a>
              <ul className="dropdown-menu text-small">
                {userData.role_level === 0 && (
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => navigate("/userlist")}
                    >
                      {t("사용자 관리")}
                    </a>
                  </li>
                )}
                {userData.role_level === 0 && (
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => navigate("/companylist")}
                    >
                      {t("기업 관리")}
                    </a>
                  </li>
                )}
                {/* <li>
                <a className="dropdown-item" href="#">
                  New project...
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li> */}
                {/* <li>
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              </li> */}
                {/* <li>
                <hr className="dropdown-divider" />
              </li> */}
                <li>
                  <a
                    className="dropdown-item"
                    data-bs-toggle="modal"
                    data-bs-target="#languageModal"
                    onClick={() => setShowLanguageModal(true)}
                  >
                    {t("언어 변경")}
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" onClick={() => socialLogout()}>
                    {t("로그아웃")}
                  </a>
                </li>
              </ul>
            </div>
          )}
          {!isLoggedIn && (
            <div className="text-end">
              <a
                onClick={() => {
                  if (navigator.userAgent.includes("Safari")) {
                    loginWithGoogle();
                  } else {
                    googleLoginRef.current
                      .querySelector("[role=button]")
                      .click();
                  }
                }}
                className="btn btn-dark me-2"
              >
                {t("로그인/회원가입")}
              </a>
              <a
                onClick={() => {
                  Swal.fire({
                    iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
                    title: t("지금 무료로 시작하기"),
                    text: t("7일간 무료 이용이 가능합니다."),
                    showCancelButton: true,
                    confirmButtonText: t("네"),
                    cancelButtonText: t("아니오"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      if (navigator.userAgent.includes("Safari")) {
                        loginWithGoogle();
                      } else {
                        googleLoginRef.current
                          .querySelector("[role=button]")
                          .click();
                      }
                    }
                  });
                }}
                className="btn btn-outline-danger"
              >
                {t("지금 무료로 시작하기")}
              </a>
              <div
                className="google-login"
                style={{ display: "none" }}
                ref={googleLoginRef}
              >
                {/* {!navigator.userAgent.includes("Safari") && ( */}
                <GoogleOAuthProvider clientId={googleClientId}>
                  <GoogleLogin
                    onSuccess={onGoogleSuccess}
                    onError={onGoogleFailure}
                    size="large"
                    shape="rectangular"
                    logo_alignment="left"
                    type="standard"
                    width="300px"
                    height="52px"
                    useOneTap={false}
                  />
                </GoogleOAuthProvider>
                {/* )} */}

                {/* <img
                      className="google-login-button"
                      src="./assets/images/sign-in/google-login-button-en.png"
                      width="300px"
                      height="auto"
                      alt="구글 로그인 버튼"
                      onClick={() => {
                        if (navigator.userAgent.includes("Safari"))
                          loginWithGoogle();
                      }}
                    /> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="languageModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="languageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="languageModalLabel">
                {t("언어 변경")}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowLanguageModal(false)}
              ></button>
            </div>
            <div className="modal-body" style={{ textAlign: "center" }}>
              {userData && (
                <div className="row g-2">
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "EN" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("EN");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      English(EN)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "KO" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("KO");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      한국어(KO)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "JP" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("JP");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      日本語(JP)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "FR" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("FR");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Français(FR)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "ES" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("ES");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Español(ES)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "DE" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("DE");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Deutsch(DE)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "IT" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("IT");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Italiano(IT)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "RU" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("RU");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Русский(RU)
                    </button>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <button
                      className={`btn btn-${
                        userData.lang === "NL" ? "danger" : "outline-dark"
                      }`}
                      style={{ width: "140px" }}
                      onClick={() => {
                        setShowLanguageModal(false);
                        saveLanguage("NL");
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Nederlands(NL)
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
