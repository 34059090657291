import React, { useState, useEffect, useRef } from "react";
import { $put, $post, $get } from "../utils/common";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useAuth } from "../login/AuthProvider";

function ProjectInfoModal(props) {
  const appUrl = process.env.REACT_APP_URL;
  const { t, i18n } = useTranslation();
  const { isLoggedIn, userData } = useAuth();

  const [info, setInfo] = useState({
    project_title: "",
    brand_title: "",
    keyword: "",
    // tag: "",
    reference: "",
    topic: "",
    category_id: 1,
    target: "",
  });
  const [categoryList, setCategoryList] = useState([]);
  const closeButtonRef = useRef();

  const saveProjectInfo = async (e) => {
    if (!info.project_title) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("프로젝트명을 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!info.brand_title) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("브랜드명을 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!info.target) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("대상고객을 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!info.keyword) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("핵심키워드를 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!info.reference) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("채널 정보를 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (!info.topic) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("채널에서 제공되는 주된 정보를 입력하세요."),
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }

    if (info.id) {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("프로젝트 정보 저장"),
        text: t("프로젝트 정보를 저장하시겠습니까?"),
        showCancelButton: true,
        confirmButtonText: t("예"),
        cancelButtonText: t("아니오"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $put(`/api/project/${info.id}`, {
            project_title: info.project_title,
            brand_title: info.brand_title,
            keyword: info.keyword,
            // tag: info.tag,
            reference: info.reference,
            topic: info.topic,
            category_id: info.category_id,
            target: info.target,
          });

          if (res.status === 200) {
            props.onList();
            closeButtonRef.current.click();
          } else {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
              title: t("프로젝트 정보 저장 실패"),
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    } else {
      Swal.fire({
        iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" class="swal-custom-icon" alt="logo" />`,
        title: t("프로젝트 추가"),
        text: t("프로젝트 정보를 저장하시겠습니까?"),
        showCancelButton: true,
        confirmButtonText: t("예"),
        cancelButtonText: t("아니오"),
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await $post(`/api/project`, {
            project_title: info.project_title,
            brand_title: info.brand_title,
            keyword: info.keyword,
            tag: info.tag,
            reference: info.reference,
            topic: info.topic,
            category_id: info.category_id,
            target: info.target,
          });

          if (res.status === 200) {
            props.onList();
            closeButtonRef.current.click();
          } else {
            Swal.fire({
              iconHtml: `<img src="${appUrl}/assets/images/sdot-logo.png" className="swal-custom-icon" alt="logo" />`,
              title: t("프로젝트 정보 저장 실패"),
              text: res.message,
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
    }
  };

  const getCategoryList = async () => {
    const res = await $get(`/api/project/category`);
    if (res.status === 200) {
      setCategoryList(res.data);
    }
  };

  const getWorkDetail = async () => {
    const res = await $get(`/api/work/${props.workId}`);
    if (res.status === 200) {
      setInfo(res.data);
    }
  };

  const closeModal = () => {
    // setInfo({
    //   project_title: "",
    //   brand_title: "",
    //   keyword: "",
    //   // tag: "",
    //   reference: "",
    //   topic: "",
    //   category_id: 1,
    //   target: "",
    // });
  };

  const getProjectDetail = async () => {
    const res = await $get(`/api/project/${props.selectedProjectId}`);
    if (res.status === 200) {
      setInfo(res.data);
    }
  };

  useEffect(() => {
    getCategoryList();
    // getWorkDetail();
  }, []);

  useEffect(() => {
    if (props.selectedProjectId !== null) {
      // setInfo(props.selectedProject);
      getProjectDetail();
    } else {
      setInfo({
        project_title: "",
        brand_title: "",
        keyword: "",
        // tag: "",
        reference: "",
        topic: "",
        category_id: 1,
        target: "",
      });
    }
  }, [props.selectedProjectId]);

  return (
    <div
      className="modal fade"
      id="projectInfoModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="projectInfoModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="projectInfoModalLabel">
              {t("프로젝트")}{" "}
              {userData && userData.lang === "KO" && (
                <a
                  className="btn btn-link fs-6 text-dark"
                  href="https://wondersaiguide.oopy.io/1b931784-036f-4e87-83b3-625d9fd489c3"
                  target="_blank"
                >
                  ({t("등록 가이드 보기")})
                </a>
              )}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={closeButtonRef}
              onClick={() => closeModal()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="projectName" className="form-label">
                {t("프로젝트명")} <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                id="projectName"
                value={info.project_title || ""}
                onChange={(e) =>
                  setInfo({ ...info, project_title: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="brandName" className="form-label">
                {t("브랜드명")} <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                id="brandName"
                value={info.brand_title || ""}
                onChange={(e) =>
                  setInfo({ ...info, brand_title: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="category" className="form-label">
                {t("카테고리")} <i className="text-danger">*</i>
              </label>
              <select
                className="form-select"
                id="category"
                value={info.category_id}
                onChange={(e) =>
                  setInfo({ ...info, category_id: e.target.value })
                }
              >
                {categoryList.map((category) => (
                  <option value={category.id} key={category.id}>
                    {t(category.category_title)}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="target" className="form-label">
                {t("대상고객")} <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                id="target"
                value={info.target || ""}
                onChange={(e) => setInfo({ ...info, target: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="keyword" className="form-label">
                {t("핵심키워드")} <i className="text-danger">*</i>
              </label>
              <input
                type="text"
                className="form-control"
                id="keyword"
                value={info.keyword || ""}
                onChange={(e) => setInfo({ ...info, keyword: e.target.value })}
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="tag" className="form-label">
                태그
              </label>
              <input
                type="text"
                className="form-control"
                id="tag"
                placeholder="#태그1 #태그2 #태그3"
                value={info.tag}
                onChange={(e) => setInfo({ ...info, tag: e.target.value })}
              />
            </div> */}

            <div className="mb-3">
              <label htmlFor="reference" className="form-label">
                {t("채널 정보")} <i className="text-danger">*</i>{" "}
              </label>
              {/* <p>
                <i
                  className="text-muted"
                  style={{
                    display: userData && userData.lang === "KO" ? "" : "none",
                  }}
                >
                  인스타그램 전용 프로젝트인 경우 피드포맷을 하단에 추가해주시면
                  좋습니다.(
                  <a
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      setInfo({
                        ...info,
                        reference:
                          info.reference +
                          "\n\n[인스타그램 피드 포맷: 전체적인 흐름을 이렇게 잡아줄 것, 나눠서 내용을 제시하는 것은 아님]\n- 후킹문구: 대상 고객을 후킹할 수 있는 문구, 페인포인트를 제시해 공감을 불러일으키거나, 궁금증을 유발하는 문구\n- 필수 내용 요약: 피드에서 전달하고자 하는 핵심 내용 요약\n- CTA(Call To Action) 문구: 좋아요, 댓글, 공유, 팔로우 등 대상 고객의 반응을 이끌어내는 문구",
                      });
                    }}
                  >
                    추천 피드포맷 추가하기
                  </a>
                  )
                </i>
              </p> */}
              <textarea
                className="form-control"
                id="reference"
                rows="5"
                value={info.reference || ""}
                onChange={(e) =>
                  setInfo({ ...info, reference: e.target.value })
                }
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="topic" className="form-label">
                {t("채널에서 제공되는 주된 정보")}{" "}
                <i className="text-danger">*</i>
              </label>
              <textarea
                className="form-control"
                id="topic"
                rows="5"
                value={info.topic || ""}
                onChange={(e) => setInfo({ ...info, topic: e.target.value })}
              ></textarea>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-between">
            {userData && userData.lang === "KO" && (
              <a
                className="btn btn-dark"
                href="https://wondersaiguide.oopy.io/1b931784-036f-4e87-83b3-625d9fd489c3"
                target="_blank"
              >
                {t("프로젝트 등록 가이드")}
              </a>
            )}

            <div>
              <button
                type="button"
                className="btn btn-secondary me-1"
                data-bs-dismiss="modal"
                onClick={() => closeModal()}
              >
                {t("닫기")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => saveProjectInfo()}
              >
                {t("저장")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectInfoModal;
